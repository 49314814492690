import { Injectable } from '@angular/core';

export interface BadgeItem {
	type: string;
	value: string;
}

export interface ChildrenItems {
	state: string;
	target?: boolean;
	name: string;
	type?: string;
	children?: ChildrenItems[];
	role?: string;
}

export interface MainMenuItems {
	state: string;
	main_state?: string;
	target?: boolean;
	name: string;
	type: string;
	icon: string;
	badge?: BadgeItem[];
	children?: ChildrenItems[];
	role?: string;
}

export interface Menu {
	label: string;
	main: MainMenuItems[];
	role?: string;
}

const MENUITEMS = [
	{
		label: 'Dashboard',
		role: 'Dashboard - DefaultDashboard',
		main: [
			{
				state: 'dashboard',
				name: 'Dashboard',
				type: 'link',
				role: 'Dashboard - DefaultDashboard',
				icon: 'ti-home'
			},
		],
	},
	{
		label: 'Students',
		role: 'Students',
		main: [
			{
				state: 'students',
				name: 'Students',
				type: 'sub',
				icon: 'fa fa-graduation-cap',
				role: 'Students',
				children: [
					{
						state: 'admissions',
						role: 'Students - Admission',
						name: 'Admission'
					},
					{
						state: 'admissions-upload',
						role: 'Students - Admission',
						name: 'Admission - Upload'
					},
					{
						state: 'guardians',
						name: 'Guardians',
						type: 'sub',
						role: 'Students - Admission',
						children: [
							{
								state: 'upload',
								name: 'Upload',
								role: 'Students - Admission',
								target: false
							},
							{
								state: 'list',
								name: 'Guardian List',
								role: 'Students - Admission',
								target: false
							},
						]
					},
					{
						state: 'view-all',
						role: 'Students - View All Student',
						name: 'Students List'
					},
					{
						state: 'details',
						role: 'Students - View All Student',
						name: 'Student Details'
					},
					{
						state: 'class-list',
						role: 'Students - Admission',
						name: 'Class List'
					},
					{
						state: 'active-class-list',
						role: 'Students - Admission',
						name: 'Active Class List'
					},
					{
						state: 'upload-multiple-pictures',
						role: 'Students - Admission',
						name: 'Upload Pictures'
					},
					{
						state: 'promotions',
						role: 'Students',
						name: 'Student Promotion'
					},
					{
						state: 'daily-activity',
						name: 'Daily Activity',
						type: 'sub',
						role: 'Students',
						children: [
							{
								state: 'class-daily-activity',
								role: 'Students',
								name: 'View and Create'
							},
							{
								state: 'generate-class-report-type',
								role: 'Students',
								name: 'Generate Activity Report'
							},
						]
					},
					{
						state: 'assign-subject-to-student',
						role: 'Subjects - Assign To Student',
						name: 'Assign Subject'
					},
					{
						state: 'assign-student-electives',
						role: 'Subjects - Assign To Student',
						name: 'Assign Student Electives'
					},
					{
						state: 'assign-student-electives-bulk',
						role: 'Subjects - Assign To Student',
						name: 'Assign Electives - Bulk'
					}
				]
			}
		]
	},
	{
		label: 'Human Resource',
		role: 'Human Resources',
		main: [
			{
				state: 'human-resources',
				name: 'Employees',
				type: 'sub',
				icon: 'fa fa-group',
				role: 'Human Resources',
				children: [
					{
						state: 'manage-employees',
						role: 'Human Resources',
						name: 'Human Resource'
					},
					{
						state: 'admission-basic-info',
						role: 'Human Resources - Add Employee',
						name: 'New Employee'
					},
					{
						state: 'upload-employee-data',
						role: 'Human Resources - Add Employee',
						name: 'New Employee - Excel'
					},
					{
						state: 'details',
						role: 'Human Resources - View Employee',
						name: 'Employee Details'
					},
					{
						state: 'employees-list',
						role: 'Human Resources - View Employees',
						name: 'Employee List'
					},
					{
						state: 'categories',
						role: 'Human Resources - Employee Category',
						name: 'Category'
					},
					{
						state: 'departments',
						role: 'Human Resources - Employee Department',
						name: 'Departments'
					},
					{
						state: 'positions',
						role: 'Human Resources - Employee Position',
						name: 'Positions'
					},
				]
			},
		]
	},
	{
		label: 'Users',
		role: 'Users',
		main: [
			{
				state: 'users',
				name: 'Users',
				type: 'sub',
				icon: 'ti-user',
				role: 'Users',
				children: [
					{
						state: 'manage-users',
						role: 'Users - Manage Users',
						name: 'Manage Users'
					},
					{
						state: 'users-activity',
						role: 'Users - Manage Users',
						name: 'User Activities'
					}
				]
			}
		]
	},
	{
		label: 'Subjects',
		role: 'Subjects',
		main: [
			{
				state: 'subjects',
				name: 'Subjects',
				type: 'sub',
				icon: 'ti-receipt',
				role: 'Subjects',
				children: [
					{
						state: 'view-all',
						role: 'Subjects - View All',
						name: 'All Subjects'
					},
					{
						state: 'assign-subject-to-class',
						role: 'Subjects - Assign Class',
						name: 'Class Subjects'
					},
					{
						state: 'assign-subject-to-teacher',
						role: 'Subjects - Assign Teacher',
						name: 'Subject Teacher'
					},
					{
						state: 'class-with-subject-teachers',
						role: 'Subjects - View All',
						name: 'Class With Subject Trs.'
					},
					{
						state: 'raw-scores',
						role: 'Subjects',
						name: 'Raw Score Subjects'
					}
				]
			}
		]
	},
	{
		label: 'Attendance',
		role: 'Attendances',
		main: [
			{
				state: 'students',
				name: 'Attendance',
				type: 'sub',
				icon: 'ti-check-box',
				role: 'Attendances',
				children: [
					{
						state: 'student-attendance',
						role: 'Attendances',
						name: 'View Attendance'
					},
					{
						state: 'mark-student-attendance',
						role: 'Attendances',
						name: 'Mark Attendance'
					},
				]
			}
		]
	},
	{
		label: 'Examinations',
		role: 'Examination',
		main: [
			{
				state: 'examinations',
				name: 'Examinations Center',
				type: 'sub',
				icon: 'ti-pencil-alt',
				role: 'Examination',
				children: [

					{
						state: 'index',
						name: 'Main Control',
						role: 'Examination',
						target: false
					},
					{
						state: 'enter-marks',
						name: 'Enter Marks | End of Term',
						role: 'Examination - EnterResults',
						target: false
					},
					{
						state: 'enter-class-marks',
						name: 'Enter Marks | Class Marks',
						role: 'Examination - EnterResults',
						target: false
					},
					{
						state: 'enter-batch-marks',
						name: 'Enter Batch Marks',
						role: 'Examination - EnterResults',
						target: false
					},
					{
						state: 'student-transcripts',
						name: 'Transcripts',
						role: 'Reports - Print All Result',
						target: false
					},
					{
						state: 'mock-examinations',
						name: 'Mock Examinations',
						type: 'sub',
						role: 'MockExamination',
						children: [
							{
								state: 'main-controls',
								name: 'Main Controls',
								role: 'MockExamination',
								target: false
							},
							{
								state: 'mock-types',
								name: 'Mock Types',
								role: 'MockExamination - Add Mock Type',
								target: false
							},
							{
								state: 'enter-marks',
								name: 'Enter Marks',
								role: 'MockExamination - Enter Marks',
								target: false
							}
						]
					},
					{
						state: 'reports',
						name: 'Reports',
						type: 'sub',
						role: 'Reports',
						children: [
							{
								state: 'report-types',
								name: 'Report Types',
								role: 'Reports - Report Type Details',
								target: false
							},
							{
								state: 'group-wise-reports',
								name: 'Group Wise Reports',
								role: 'Reports - GroupWise Report',
								target: false
							},
							{
								state: 'analysis',
								name: 'Analysis',
								role: 'Reports - GroupWise Report',
								target: false
							}, {
								state: 'edu-class-block-sheet',
								name: 'Class Work Sheet',
								role: 'Reports - GroupWise Report',
								target: false
							}
						]
					},
					{
						state: 'remarks',
						name: 'Remarks',
						type: 'sub',
						role: 'Remarks',
						children: [
							{
								state: 'student-conducts',
								name: 'Student Conducts',
								role: 'Remarks - Student Conduct',
								target: false
							},
							{
								state: 'student-attitudes',
								name: 'Student Attitudes',
								role: 'Remarks - Student Attitude',
								target: false
							},
							{
								state: 'student-interests',
								name: 'Student Interests',
								role: 'Remarks - Student Interest',
								target: false
							},
							{
								state: 'class-teacher\'s-remarks',
								name: 'Class Teacher\'s Remarks',
								role: 'Remarks - Class Teacher Remark',
								target: false
							},
							{
								state: 'head-teacher\'s-remarks',
								name: 'Head Teacher\'s Remarks',
								role: 'Remarks - Head Teacher Remarks',
								target: false
							}
						]
					},
					{
						state: 'clean-class-mark',
						name: 'Clean Class Mark',
						role: 'isHeadTeacher',
						target: false
					},
				]
			}
		]
	},
	{
		label: 'Finance',
		role: 'Finances',
		main: [
			{
				state: 'finances',
				name: 'Finances',
				type: 'sub',
				icon: 'ti-money',
				role: 'Finances',
				children: [
					{
						state: 'incomes',
						name: 'INCOME',
						type: 'sub',
						role: 'Finances',
						children: [
							{
								state: 'types',
								role: 'Finances',
								name: 'Income Types'
							}, {
								state: 'add',
								role: 'Finances',
								name: 'Add Income'
							},
							{
								state: 'all',
								role: 'Finances',
								name: 'Income'
							}
						]
					},
					{
						state: 'expenses',
						name: 'EXPENSES',
						type: 'sub',
						role: 'Finances',
						children: [
							{
								state: 'types',
								role: 'Finances',
								name: 'Expense Types'
							},
							{
								state: 'add',
								role: 'Finances',
								name: 'Add Expense'
							},
							{
								state: 'all',
								role: 'Finances',
								name: 'Expenses'
							}
						]
					},
					{
						state: 'student-bills',
						name: 'STUDENTS BILL',
						type: 'sub',
						role: 'Finances',
						children: [
							{
								state: 'bill-class',
								role: 'Finances Bill Student',
								name: 'Bill Class'
							},
							{
								state: 'print-class-bills',
								role: 'Finances',
								name: 'Print Class Bills'
							},
							{
								state: 'student-transactions',
								role: 'Finances',
								name: 'Student Transactions'
							},
							{
								state: 'publish-student-bills',
								role: 'Finances',
								name: 'Publish Student Bills'
							}
						]
					},
					{
						state: 'online-payments',
						name: 'ONLINE PAYMENTS',
						type: 'sub',
						role: 'Finances',
						children: [
							{
								state: 'search',
								role: 'Finances Reports',
								name: 'Momo'
							},
							{
								state: 'search',
								role: 'Finances Reports',
								name: 'Visa Card'
							},
							{
								state: 'search',
								role: 'Finances Reports',
								name: 'Search Payments'
							},
						]
					},
					{
						state: 'daily-collections',
						name: 'DAILY COLLECTIONS',
						type: 'sub',
						role: 'Finances',
						children: [
							{
								state: 'collect',
								role: 'Finances Receive Payment',
								name: 'Add New'
							},
							{
								state: 'history',
								role: 'Finances Reports',
								name: 'History'
							},
							{
								state: 'settings',
								role: 'Finances',
								name: 'Settings'
							}
						]
					},
					{
						state: 'archives',
						name: 'ARCHIVES',
						type: 'sub',
						role: 'Finances',
						children: [
							{
								state: 'student-transactions',
								role: 'Finances',
								name: 'Student Transactions'
							},
							{
								state: 'bill-receivables',
								role: 'Finances Reports',
								name: 'Bill Receivables'
							}
						]
					},
					{
						state: 'reports',
						name: 'REPORTS',
						type: 'sub',
						role: 'Finances',
						children: [
							{
								state: 'my-collections',
								role: 'Finances',
								name: 'My Collections'
							},
							{
								state: 'profit-and-loss',
								role: 'Finances Reports',
								name: 'Profit & Loss'
							},
							{
								state: 'bill-receivable',
								role: 'Finances Reports',
								name: 'Bill Receivable'
							},
							{
								state: 'student-payment-breakdowns-classes',
								role: 'Finances Reports',
								name: 'Payment Breakdown'
							},
							{
								state: 'student-payment-breakdowns-daily',
								role: 'Finances Reports',
								name: 'Payment Breakdown Daily'
							},
							{
								state: 'student-payments',
								role: 'Finances Reports',
								name: 'Payment History'
							}
						]
					},
					{
						state: 'settings',
						name: 'SETTINGS',
						type: 'sub',
						role: 'Finances',
						children: [
							{
								state: 'bill-items',
								role: 'Finances',
								name: 'Bill Items Setup'
							},
							{
								state: 'currencies',
								role: 'Finances',
								name: 'Currency Setup'
							}
						]
					}
				]
			}
		]
	},
	// {
	//   label: 'Canteens',
	//   role: 'Canteens',
	//   main: [
	//     {
	//       state: 'canteens',
	//       name: 'Manage Canteen',
	//       type: 'sub',
	//       icon: 'icofont icofont-fast-food',
	//       role: 'Canteens',
	//       children: [
	//         {
	//           state: 'main',
	//           role: 'Canteens',
	//           name: 'MAIN'
	//         },
	//         {
	//           state: 'receive-payments',
	//           role: 'Canteens Receive Payment',
	//           name: 'RECEIVE PAYMENTS'
	//         },
	//         {
	//           state: 'reports',
	//           name: 'REPORTS',
	//           type: 'sub',
	//           role: 'Canteens',
	//           children: [
	//             {
	//               state: 'register',
	//               role: 'Canteens',
	//               name: 'Register Report'
	//             },
	//             {
	//               state: 'payments',
	//               role: 'Canteens',
	//               name: 'Payments Report'
	//             },
	//             {
	//               state: 'receivables',
	//               role: 'Canteens',
	//               name: 'Receivables'
	//             },
	//           ]
	//         },
	//         {
	//           state: 'settings',
	//           name: 'SETTINGS',
	//           type: 'sub',
	//           role: 'Canteens',
	//           children: [
	//             {
	//               state: 'bill-students',
	//               role: 'Canteens Bill',
	//               name: 'Bill Students'
	//             },
	//             {
	//               state: 'canteen-bills',
	//               role: 'Canteens Bill',
	//               name: 'Canteen Bills'
	//             }
	//           ]
	//         },
	//       ]
	//     }
	//   ]
	// },
	{
		label: 'Canteens & Others',
		role: 'Extra Bill',
		main: [
			{
				state: 'extra-bills',
				name: 'Manage Canteen & Others',
				type: 'sub',
				icon: 'icofont icofont-fast-food',
				role: 'Extra Bill',
				children: [
					{
						state: 'receive-payments',
						role: 'Extra Bill Receive Payment',
						name: 'RECEIVE PAYMENTS'
					},
					{
						state: 'my-collections',
						role: 'Extra Bill Receive Payment',
						name: 'MY COLLECTIONS'
					},
					{
						state: 'reports',
						name: 'REPORTS',
						type: 'sub',
						role: 'Extra Bill Reports',
						children: [
							{
								state: 'register',
								role: 'Extra Bill Reports',
								name: 'Register Report'
							},
							{
								state: 'payments',
								role: 'Extra Bill Reports',
								name: 'Payments Report'
							},
							{
								state: 'receivables',
								role: 'Extra Bill Reports',
								name: 'Receivables'
							},
							{
								state: 'item-receivables',
								role: 'Extra Bill Reports',
								name: 'Receivables Per Item'
							},
							{
								state: 'breakdowns',
								role: 'Extra Bill Reports',
								name: 'Breakdowns'
							}
						]
					},
					{
						state: 'settings',
						name: 'SETTINGS',
						type: 'sub',
						role: 'Extra Bill',
						children: [
							{
								state: 'items',
								role: 'Extra Bill Add Bill Item',
								name: 'Bill Items'
							},
							{
								state: 'bill-students',
								role: 'Extra Bill Add Bill',
								name: 'Bill Students'
							},
							{
								state: 'bill-students-with-amount',
								role: 'Extra Bill Add With Amount',
								name: 'Bill With Amount'
							}
						]
					},
				]
			}
		]
	},
	{
		label: 'Independent Bills',
		role: 'Extra Bill',
		main: [
			{
				state: 'independent-bills',
				name: 'Manage Independent Bills',
				type: 'sub',
				icon: 'ti-bar-chart',
				role: 'Extra Bill',
				children: [
					{
						state: 'receive-payments',
						role: 'Extra Bill Receive Payment',
						name: 'RECEIVE PAYMENTS'
					},
					{
						state: 'my-collections',
						role: 'Extra Bill Receive Payment',
						name: 'MY COLLECTIONS'
					},
					{
						state: 'reports',
						name: 'REPORTS',
						type: 'sub',
						role: 'Extra Bill Reports',
						children: [
							{
								state: 'register',
								role: 'Extra Bill Reports',
								name: 'Register Report'
							},
							{
								state: 'payments',
								role: 'Extra Bill Reports',
								name: 'Payments Report'
							},
							// {
							//   state: 'receivables',
							//   role: 'Extra Bill Reports',
							//   name: 'Receivables'
							// },
							{
								state: 'item-receivables',
								role: 'Extra Bill Reports',
								name: 'Receivables Per Item'
							},
							{
								state: 'breakdowns',
								role: 'Extra Bill Reports',
								name: 'Breakdowns'
							}
						]
					},
					{
						state: 'settings',
						name: 'SETTINGS',
						type: 'sub',
						role: 'Extra Bill',
						children: [
							{
								state: 'items',
								role: 'Extra Bill Add Bill Item',
								name: 'Bill Items'
							},
							{
								state: 'bill-students',
								role: 'Extra Bill Add Bill',
								name: 'Bill Students'
							},
							{
								state: 'bill-students-with-amount',
								role: 'Extra Bill Add With Amount',
								name: 'Bill With Amount'
							}
						]
					},
				]
			}
		]
	},
	{
		label: 'STORES',
		role: 'Stocks',
		main: [
			{
				state: 'stores',
				name: 'Manage Stores',
				type: 'sub',
				icon: 'ti-harddrives',
				role: 'Stocks',
				children: [
					{
						state: 'sell-products',
						role: 'Stocks Sell Product',
						name: 'SELL'
					},
					{
						state: 'my-collections',
						role: 'Stocks Sell Product',
						name: 'MY COLLECTIONS'
					},
					{
						state: 'search-invoice',
						role: 'Stocks Sell Product',
						name: 'SEARCH INVOICE'
					},
					{
						state: 'products',
						name: 'PRODUCTS',
						type: 'sub',
						role: 'Stocks',
						children: [
							{
								state: 'new',
								role: 'Stocks Add Product',
								name: 'Add New'
							},
							{
								state: 'category',
								role: 'Stocks Add Category',
								name: 'Category'
							}
						]
					},
					{
						state: 'reports',
						name: 'REPORTS',
						type: 'sub',
						role: 'Stocks',
						children: [
							{
								state: 'invoice-history',
								role: 'Stocks Sell Product',
								name: 'Invoice History'
							},
							{
								state: 'remaining',
								role: 'Stocks Report Raw',
								name: 'Stock Levels'
							},
							{
								state: 'payments',
								role: 'Stocks Report Raw',
								name: 'Payment History'
							},
							{
								state: 'sales',
								role: 'Stocks Report Raw',
								name: 'Product Sales'
							},
							{
								state: 'debtors',
								role: 'Stocks Report Raw',
								name: 'Debtors'
							}
						]
					},
				]
			}
		]
	},
	{
		label: 'INVENTORY',
		role: 'INVENTORY',
		main: [
			{
				state: 'inventory',
				name: 'Manage Inventory',
				type: 'sub',
				icon: 'ti-harddrives',
				role: 'INVENTORY',
				children: [
					{
						state: 'issuance',
						role: 'INVENTORY - ISSUE ITEMS',
						name: 'ISSUE ITEMS'
					},
					{
						state: 'issue-return',
						role: 'INVENTORY - RECEIVE ISSUED ITEMS BEING RETURNED',
						name: 'INVENTORY ISSUANCES'
					},
					{
						state: 'manage',
						role: 'INVENTORY - MANAGE INVENTORY PRODUCTS',
						name: 'MANAGE INVENTORY'
					},
					{
						state: 'reports',
						name: 'REPORTS',
						type: 'sub',
						role: 'Stocks',
						children: [
							{
								state: 'stock-levels',
								role: 'INVENTORY - STOCK LEVEL REPORT',
								name: 'Stock Levels Report'
							},
							{
								state: 'issue-history',
								role: 'INVENTORY - INVENTORY ISSUE HISTORY REPORT',
								name: 'Issue History'
							}
						]
					},
				]
			}
		]
	},
	{
		label: 'Teacher Learner Materials',
		role: 'TLM - Approve TLM',
		main: [
			{
				state: 'teacher-learner-materials',
				name: 'Teacher Learner Materials',
				type: 'sub',
				icon: 'fa fa-briefcase',
				role: 'TLM - Approve TLM',
				children: [

					{
						state: 'approve',
						name: 'Teacher Learner Materials',
						role: 'TLM - Approve TLM',
						target: false
					}
				]
			}
		]
	},
	{
		label: 'Settings',
		role: 'GeneralSettings',
		main: [
			{
				state: 'general-settings',
				name: 'General Settings',
				type: 'sub',
				icon: 'fa fa-cogs',
				role: 'GeneralSettings',
				children: [
					{
						state: 'index',
						role: 'GeneralSettings',
						name: 'Main Settings'
					},
					{
						state: 'school-details',
						role: 'GeneralSettings - Change School Settings',
						name: 'School Details'
					},
					{
						state: 'manage-classes',
						role: 'GeneralSettings - Add Class',
						name: 'Classes'
					},
					{
						state: 'weeks',
						role: 'Weeks - Add Week',
						name: 'Weeks'
					},
					{
						state: 'grading-systems',
						role: 'GeneralSettings - Grading System',
						name: 'Grading System'
					},
					{
						state: 'scores-break-down',
						role: 'GeneralSettings - Grading System',
						name: 'Score Break Down'
					},
					{
						state: 'academic-calendars',
						role: 'GeneralSettings - Academic Year',
						name: 'Academic Calendars'
					},
					{
						state: 'publish-examinations',
						role: 'GeneralSettings',
						name: 'Publish Examination'
					}
				]
			},
		]
	},
	{
		label: 'Messaging Center',
		role: 'SMS',
		main: [
			{
				state: 'messaging-center',
				name: 'Messaging Center',
				type: 'sub',
				icon: 'ti-comment-alt',
				role: 'Dashboard - DefaultDashboard',
				children: [
					{
						state: 'sms',
						name: 'SMS',
						type: 'sub',
						role: 'SMS',
						children: [
							{
								state: 'dashboard',
								role: 'SMS',
								name: 'Dashboard'
							},
							{
								state: 'send-to-class',
								role: 'SMS',
								name: 'Send to Classes | Parents'
							},
							{
								state: 'send-to-employees',
								role: 'SMS',
								name: 'Send to Employees'
							},
							{
								state: 'send-to-employees-department',
								role: 'SMS',
								name: 'Send to Employees Dept.'
							},
							{
								state: 'send-quick-sms',
								role: 'SMS',
								name: 'Send Quick SMS'
							}, {
								state: 'excel',
								role: 'SMS',
								name: 'Send SMS (Excel)'
							}
						]
					},
					{
						state: 'notifications',
						name: 'NOTIFICATIONS',
						type: 'sub',
						role: 'Dashboard - DefaultDashboard',
						children: [
							{
								state: 'read',
								role: 'Dashboard - DefaultDashboard',
								name: 'View'
							},
							{
								state: 'send',
								role: 'Notifications - Send',
								name: 'Send Notifications'
							}
						]
					}
				]
			}
		]
	}
];
const STUDENTITEMS = [
	{
		label: 'Dashboard',
		main: [
			{
				state: 'dashboard',
				name: 'Dashboard',
				type: 'link',
				icon: 'ti-home'
			},
			{
				state: 'profile',
				name: 'Profile',
				type: 'link',
				icon: 'ti-id-badge'
			},
			{
				state: 'account-settings',
				name: 'Settings',
				type: 'link',
				icon: 'ti-settings'
			}
		],
	},
	{
		label: 'Result Checker',
		main: [
			{
				state: 'results-checker',
				name: 'Results',
				type: 'sub',
				icon: 'fa fa-book',
				children: [
					{
						state: 'termly',
						name: 'Termly Results'
					},
					{
						state: 'mock-examination',
						name: 'Mock Examination'
					}
				]
			},
		]
	},
	{
		label: 'Course Materials',
		main: [
			{
				state: 'course-materials',
				name: 'Course Materials',
				type: 'sub',
				icon: 'fa fa-briefcase',
				children: [
					{
						state: 'all',
						name: 'All'
					}
				]
			},
		]
	},
	{
		label: 'Online Examination',
		main: [
			{
				state: 'online-examinations',
				name: 'Online Examination',
				type: 'sub',
				icon: 'ti-pencil-alt',
				children: [
					{
						state: 'all',
						name: 'Available Examinations'
					},
					{
						state: 'random-examination',
						name: 'Random Examinations'
					}
				]
			},
		]
	},
];
const GUARDIANITEMS = [
	{
		label: 'Dashboard',
		main: [
			{
				state: 'dashboard',
				name: 'Dashboard',
				type: 'link',
				icon: 'ti-home'
			},
			{
				state: 'account-settings',
				name: 'Settings',
				type: 'link',
				icon: 'ti-settings'
			}
		],
	},
	{
		label: "WARD ACADEMICS",
		main: [
			{
				state: 'results-checker',
				name: 'Results',
				type: 'sub',
				icon: 'fa fa-book',
				children: [
					{
						state: 'termly',
						name: 'Termly Results'
					},
					{
						state: 'mock-examination',
						name: 'Mock Examination'
					}
				]
			},
			{
				state: 'daily-activity',
				name: 'Daily Activity',
				icon: 'fa fa-file',
				type: 'link'
			}
		]
	},
	{
		label: 'WARD FINANCE',
		main: [
			{
				state: 'ward-finances',
				name: 'Bills',
				type: 'sub',
				icon: 'ti-money',
				children: [
					{
						state: 'termly',
						name: 'Termly Bills'
					}
				]
			},
		]
	}
];
const TEACHERITEMS = [
	{
		label: 'Dashboard',
		main: [
			{
				state: 'dashboard',
				name: 'Dashboard',
				type: 'link',
				icon: 'ti-home'
			},
			{
				state: 'account-settings',
				name: 'Settings',
				type: 'link',
				icon: 'ti-settings'
			}
		],
	},
	{
		label: 'TEACHER LEARNER MATERIALS',
		main: [
			{
				state: 'teacher-learner-materials',
				name: 'TLMS',
				type: 'sub',
				icon: 'fa fa-book',
				children: [
					{
						state: 'view-all',
						name: 'Upload'
					}
				]
			},
		]
	},
	{
		label: 'Online Examination',
		main: [
			{
				state: 'online-examination',
				name: 'Online Examination',
				type: 'sub',
				icon: 'ti-pencil-alt',
				children: [
					{
						state: 'view-all-online-examinations',
						name: 'All Online Examination'
					},
					{
						state: 'create-examinations',
						name: 'Create Examination'
					},
					{
						state: 'questions',
						name: 'Questions',
						type: 'sub',
						children: [
							{
								state: 'teacher-questions',
								name: 'All Questions',
								target: false
							},
							{
								state: 'add-new-question',
								name: 'Add New Question',
								target: false
							}
						]
					},
					{
						state: 'subject-topics',
						name: 'Topics'
					}
				]
			},
		]
	}
];
@Injectable()
export class MenuItems {
	getAll(): Menu[] {
		return MENUITEMS;
	}

	getStudentMenu(): Menu[] {
		return STUDENTITEMS;
	}

	getGuardianMenu(): Menu[] {
		return GUARDIANITEMS;
	}
	getTeacherMenu(): Menu[] {
		return TEACHERITEMS;
	}
}
