/**
 * Created by LifeSoft on 06/11/17.
 */
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DefaultHeaderService} from './util/default-header.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {ResidenceBody} from '../../models/util/residence-body';
import {PostalBody} from '../../models/util/postal-body';
import {ContactBody} from '../../models/util/contact-body';
import {Employee} from '../../models/employee';
import {AssignSubjectTeacherBody} from '../../models/subject';
@Injectable()
export class EmployeeService {
    base_url = environment.base_url;
    constructor(private header: DefaultHeaderService,
                private http: HttpClient,
                private router: Router) { }
    getEmployees(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/employees', {headers: this.header.getDefaultAuthHeaders()});
    }
    getActiveEmployees(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/employees?active=1', {headers: this.header.getDefaultAuthHeaders()});
    }
    getEmployeesWithDepartments(): Observable<any> {
      return this.http.get(this.base_url + 'api/v1/employees/get-employees-with-department', {headers: this.header.getDefaultAuthHeaders()});
    }
    getTeachingStaff(): Observable<any> {
      return this.http.get(this.base_url + 'api/v1/employees?category_id=2', {headers: this.header.getDefaultAuthHeaders()});
    }
    getLastEmployeeNumber(): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/employees/get-last-employee-number', {headers: this.header.getDefaultAuthHeaders()});
    }
    getEmployeeById(id: number): Observable<any> {
        return this.http.get(this.base_url + 'api/v1/employees/' + id, {headers: this.header.getDefaultAuthHeaders()});
    }
    postEmployee(body: FormData): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/employees/store',
                            body, {headers: this.header.getDefaultAuthMultiHeaders()});
    }
    updateEmployee(id: number, body: FormData): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/employees/update/' + id,
            body, {headers: this.header.getDefaultAuthMultiHeaders()});
    }
    saveEmployeeResidence(id: number, body: ResidenceBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/employees/store-residence/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    updateEmployeeResidence(id: number, body: ResidenceBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/employees/update-residence/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    saveEmployeePostal(id: number, body: PostalBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/employees/store-postal-address/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    updateEmployeePostal(id: number, body: PostalBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/employees/update-postal-address/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    saveEmployeeContact(id: number, body: ContactBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/employees/store-contact/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    updateEmployeeContact(id: number, body: ContactBody): Observable<any> {
        return this.http.put(this.base_url + 'api/v1/employees/update-contact/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    assignedSupervisor(id: number, body: any): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/employees/store-supervisor/' + id,
            JSON.stringify(body), {headers: this.header.getDefaultAuthHeaders()});
    }
    assignStudentSubject(body: AssignSubjectTeacherBody): Observable<any> {
        return this.http.post(this.base_url + 'api/v1/employees/assign-subjects', JSON.stringify(body), {
            headers: this.header.getDefaultAuthHeaders()
        });
    }
    goBackToEmployeeAdmission(): void {
        this.router.navigate(['/employees/human-resources/admission-basic-info']);
    }
    goBackToEmployees(): void {
        this.router.navigate(['/employees/human-resources/employees-list']);
    }
    getEmployeeFullname(employee: Employee): string {
     const $middleName = employee.middle_name ? employee.middle_name : '';
     return employee.first_name + ' ' + $middleName + ' ' + employee.last_name;
    }
		getDashboardSubjectStudentChartData(): Observable<any> {
				return this.http.get(this.base_url + 'api/v1/dashboard/subject-student-default-data', {headers: this.header.getDefaultAuthHeaders()});
		}
		getDashboardSubjectStudentChartAnalytics(edu_class_id, subject_id, academic_year_id, term_id): Observable<any> {
				return this.http.get(this.base_url + `api/v1/dashboard/subject-student-analytics/${edu_class_id}/${subject_id}/${academic_year_id}/${term_id}`, {headers: this.header.getDefaultAuthHeaders()});
		}
}
