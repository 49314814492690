import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GuardianGuardService} from '../../shared/guard/guardian-guard.service';
import {GuardianLayoutComponent} from './guardian-layout.component';
import {GuardianDashboardComponent} from './dashboard/guardian-dashboard.component';
import {GuardianAccountSettingsComponent} from './settings/guardian-account-settings.component';
import {CommonModule} from '@angular/common';
/**
 * Created by LifeSoft on 12/01/18.
 */
const routes: Routes = [
  {
    path: 'guardians',
    component: GuardianLayoutComponent,
    canActivate: [GuardianGuardService],
    pathMatch: 'prefix',
    children: [
      {path: '', canActivateChild: [GuardianGuardService],
        children: [
          {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
					// {path: 'dashboard', component: GuardianDashboardComponent},
          {path: 'dashboard', redirectTo: 'results-checker/termly'},
          {path: 'account-settings', component: GuardianAccountSettingsComponent},
          {path: 'results-checker',
            loadChildren: 'src/app/guardian-layout/guardian/result/guardian-result-checker.module#GuardianResultCheckerModule' },
          {path: 'ward-finances',
            // tslint:disable-next-line:max-line-length
            loadChildren: 'src/app/guardian-layout/guardian/guardian-student-finance/guardian-student-finance.module#GuardianStudentFinanceModule' }
        ]
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule]
})
export class GuardianLayoutRoutingModule { }
